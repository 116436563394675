import { RoutesEnum } from './routes.enum';
import { LocalePrefixesEnum } from './locale-prefixes.enum';

export const LOCALE_ROUTES_CONFIG: {[key in RoutesEnum]?: {[localeKey in LocalePrefixesEnum]: string}} = {
  [RoutesEnum.PRICING]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.PRICING,
    [LocalePrefixesEnum.AT]: 'preise',
    [LocalePrefixesEnum.DE]: 'preise',
    // [LocalePrefixesEnum.CH]: 'preise',
  },
  [RoutesEnum.ACCOUNTING]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ACCOUNTING,
    [LocalePrefixesEnum.AT]: 'buchhaltung',
    [LocalePrefixesEnum.DE]: 'buchhaltung',
    // [LocalePrefixesEnum.CH]: 'buchhaltung',
  },
  [RoutesEnum.SERVICES]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.SERVICES,
    [LocalePrefixesEnum.AT]: 'dienstleister',
    [LocalePrefixesEnum.DE]: 'dienstleister',
    // [LocalePrefixesEnum.CH]: 'dienstleister',
  },
  [RoutesEnum.TRADE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.TRADE,
    [LocalePrefixesEnum.AT]: 'warenwirtschaftssoftware',
    [LocalePrefixesEnum.DE]: 'warenwirtschaftssoftware',
    // [LocalePrefixesEnum.CH]: RoutesEnum.TRADE',
  },
  [RoutesEnum.ECOMMERCE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ECOMMERCE,
    [LocalePrefixesEnum.AT]: 'e-commerce',
    [LocalePrefixesEnum.DE]: 'e-commerce',
    // [LocalePrefixesEnum.CH]: RoutesEnum.ECOMMERCE',
  },
  [RoutesEnum.WAREHOUSE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.WAREHOUSE,
    [LocalePrefixesEnum.AT]: 'lagerverwaltung',
    [LocalePrefixesEnum.DE]: 'lagerverwaltung',
    // [LocalePrefixesEnum.CH]: 'lagerverwaltung',
  },
  [RoutesEnum.PRODUCTS]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.PRODUCTS,
    [LocalePrefixesEnum.AT]: 'artikelverwaltung',
    [LocalePrefixesEnum.DE]: 'artikelverwaltung',
    // [LocalePrefixesEnum.CH]: 'artikelverwaltung',
  },
  [RoutesEnum.PARTNERS]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.PARTNERS,
    [LocalePrefixesEnum.AT]: 'kundenverwaltung',
    [LocalePrefixesEnum.DE]: 'kundenverwaltung',
    // [LocalePrefixesEnum.CH]: 'kundenverwaltung',
  },
  [RoutesEnum.TEAM]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.TEAM,
    [LocalePrefixesEnum.AT]: 'personalwesen',
    [LocalePrefixesEnum.DE]: 'personalwesen',
    // [LocalePrefixesEnum.CH]: 'personalwesen',
  },
  [RoutesEnum.RETAIL]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.RETAIL,
    [LocalePrefixesEnum.AT]: 'kassensystem',
    [LocalePrefixesEnum.DE]: 'kassensystem',
    // [LocalePrefixesEnum.CH]: 'kassensystem',
  },
  [RoutesEnum.PRIVACY_POLICY]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.PRIVACY_POLICY,
    [LocalePrefixesEnum.AT]: 'datenschutzerklaerung',
    [LocalePrefixesEnum.DE]: 'datenschutzerklaerung',
    // [LocalePrefixesEnum.CH]: 'datenschutzerklaerung',
  },
  [RoutesEnum.TERMS_OF_SERVICE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.TERMS_OF_SERVICE,
    [LocalePrefixesEnum.AT]: 'agb',
    [LocalePrefixesEnum.DE]: 'agb',
    // [LocalePrefixesEnum.CH]: 'agb',
  },
  [RoutesEnum.COOKIE_POLICY]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.COOKIE_POLICY,
    [LocalePrefixesEnum.AT]: 'cookie-erklaerung',
    [LocalePrefixesEnum.DE]: 'cookie-erklaerung',
    // [LocalePrefixesEnum.CH]: 'cookie-erklaerung',
  },
  [RoutesEnum.ABOUT]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ABOUT,
    [LocalePrefixesEnum.AT]: 'ueber-runple',
    [LocalePrefixesEnum.DE]: 'ueber-runple',
    // [LocalePrefixesEnum.CH]: 'ueber-runple',
  },
  [RoutesEnum.LEGAL]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.LEGAL,
    [LocalePrefixesEnum.AT]: 'impressum',
    [LocalePrefixesEnum.DE]: 'impressum',
    // [LocalePrefixesEnum.CH]: 'impressum',
  },
  [RoutesEnum.LEXICON]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.LEXICON,
    [LocalePrefixesEnum.AT]: 'lexikon',
    [LocalePrefixesEnum.DE]: 'lexikon',
    // [LocalePrefixesEnum.CH]: 'lexikon',
  },
  [RoutesEnum.BLOG]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.BLOG,
    [LocalePrefixesEnum.AT]: RoutesEnum.BLOG,
    [LocalePrefixesEnum.DE]: RoutesEnum.BLOG,
    // [LocalePrefixesEnum.CH]: RoutesEnum.BLOG,
  },
  [RoutesEnum.ACCOUNTING_SOFTWARE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ACCOUNTING_SOFTWARE,
    [LocalePrefixesEnum.AT]: 'loesungen-buchhaltungssoftware',
    [LocalePrefixesEnum.DE]: 'loesungen-buchhaltungssoftware',
    // [LocalePrefixesEnum.CH]: RoutesEnum.ACCOUNTING_SOFTWARE,
  },
  [RoutesEnum.INVENTORY_MANAGEMENT]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.INVENTORY_MANAGEMENT,
    [LocalePrefixesEnum.AT]: 'loesungen-warenwirtschaftssoftware',
    [LocalePrefixesEnum.DE]: 'loesungen-warenwirtschaftssoftware',
    // [LocalePrefixesEnum.CH]: RoutesEnum.INVENTORY_MANAGEMENT,
  },
  [RoutesEnum.ECOMMERCE_SOFTWARE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ECOMMERCE_SOFTWARE,
    [LocalePrefixesEnum.AT]: 'loesungen-e-commerce-software',
    [LocalePrefixesEnum.DE]: 'loesungen-e-commerce-software',
    // [LocalePrefixesEnum.CH]: RoutesEnum.ECOMMERCE_SOFTWARE,
  },
  [RoutesEnum.POINT_OF_SALE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.POINT_OF_SALE,
    [LocalePrefixesEnum.AT]: 'loesungen-kassensystem',
    [LocalePrefixesEnum.DE]: 'loesungen-kassensystem',
    // [LocalePrefixesEnum.CH]: RoutesEnum.POINT_OF_SALE,
  },
  [RoutesEnum.BLOG_PAGE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.BLOG_PAGE,
    [LocalePrefixesEnum.AT]: RoutesEnum.BLOG_PAGE,
    [LocalePrefixesEnum.DE]: RoutesEnum.BLOG_PAGE,
    // [LocalePrefixesEnum.CH]: RoutesEnum.BLOG_PAGE,
  },
  [RoutesEnum.LEXICON_PAGE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.LEXICON_PAGE,
    [LocalePrefixesEnum.AT]: 'lexikon/:postName',
    [LocalePrefixesEnum.DE]: 'lexikon/:postName',
    // [LocalePrefixesEnum.CH]: RoutesEnum.LEXICON_PAGE,
  },
  [RoutesEnum.AUTHOR_PAGE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.AUTHOR_PAGE,
    [LocalePrefixesEnum.AT]: RoutesEnum.AUTHOR_PAGE,
    [LocalePrefixesEnum.DE]: RoutesEnum.AUTHOR_PAGE,
    // [LocalePrefixesEnum.CH]: RoutesEnum.AUTHOR_PAGE,
  },
  [RoutesEnum.START]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.START,
    [LocalePrefixesEnum.AT]: RoutesEnum.START,
    [LocalePrefixesEnum.DE]: RoutesEnum.START,
    // [LocalePrefixesEnum.CH]: RoutesEnum.START,
  },
  [RoutesEnum.ANALYTICS]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ANALYTICS,
    [LocalePrefixesEnum.AT]: 'business-intelligence',
    [LocalePrefixesEnum.DE]: 'business-intelligence',
    // [LocalePrefixesEnum.CH]: RoutesEnum.ANALYTICS,
  },
  [RoutesEnum.WELCOME]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.WELCOME,
    [LocalePrefixesEnum.AT]: 'willkommen',
    [LocalePrefixesEnum.DE]: 'willkommen',
    // [LocalePrefixesEnum.CH]: RoutesEnum.WELCOME,
  },
  [RoutesEnum.TRADE_SEO]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.TRADE_SEO,
    [LocalePrefixesEnum.AT]: RoutesEnum.TRADE_SEO,
    [LocalePrefixesEnum.DE]: RoutesEnum.TRADE_SEO,
    // [LocalePrefixesEnum.CH]: RoutesEnum.RoutesEnum.TRADE_SEO,
  },
  [RoutesEnum.ECOMMERCE_SEO]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ECOMMERCE_SEO,
    [LocalePrefixesEnum.AT]: RoutesEnum.ECOMMERCE_SEO,
    [LocalePrefixesEnum.DE]: RoutesEnum.ECOMMERCE_SEO,
    // [LocalePrefixesEnum.CH]: RoutesEnum.RoutesEnum.ECOMMERCE_SEO,
  },
  [RoutesEnum.ACCOUNTING_SEO]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ACCOUNTING_SEO,
    [LocalePrefixesEnum.AT]: RoutesEnum.ACCOUNTING_SEO,
    [LocalePrefixesEnum.DE]: RoutesEnum.ACCOUNTING_SEO,
    // [LocalePrefixesEnum.CH]: RoutesEnum.RoutesEnum.ACCOUNTING_SEO,
  },
  [RoutesEnum.RETAIL_SEO]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.RETAIL_SEO,
    [LocalePrefixesEnum.AT]: RoutesEnum.RETAIL_SEO,
    [LocalePrefixesEnum.DE]: RoutesEnum.RETAIL_SEO,
    // [LocalePrefixesEnum.CH]: RoutesEnum.RoutesEnum.RETAIL_SEO,
  },
  [RoutesEnum.SERVICE_SEO]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.SERVICE_SEO,
    [LocalePrefixesEnum.AT]: RoutesEnum.SERVICE_SEO,
    [LocalePrefixesEnum.DE]: RoutesEnum.SERVICE_SEO,
    // [LocalePrefixesEnum.CH]: RoutesEnum.RoutesEnum.SERVICE_SEO,
  },
  [RoutesEnum.ERP_SOFTWARE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ERP_SOFTWARE,
    [LocalePrefixesEnum.AT]: RoutesEnum.ERP_SOFTWARE,
    [LocalePrefixesEnum.DE]: RoutesEnum.ERP_SOFTWARE,
    // [LocalePrefixesEnum.CH]: RoutesEnum.RoutesEnum.ERP_SOFTWARE,
  },
  [RoutesEnum.RUNPLE_FREE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.RUNPLE_FREE,
    [LocalePrefixesEnum.AT]: RoutesEnum.RUNPLE_FREE,
    [LocalePrefixesEnum.DE]: RoutesEnum.RUNPLE_FREE,
    // [LocalePrefixesEnum.CH]: RoutesEnum.RoutesEnum.RUNPLE_FREE,
  },
  [RoutesEnum.ONLINE_STORE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.ONLINE_STORE,
    [LocalePrefixesEnum.AT]: RoutesEnum.ONLINE_STORE,
    [LocalePrefixesEnum.DE]: RoutesEnum.ONLINE_STORE,
    // [LocalePrefixesEnum.CH]: RoutesEnum.RoutesEnum.ONLINE_STORE,
  },
  [RoutesEnum.CREATE_USER]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.CREATE_USER,
    [LocalePrefixesEnum.AT]: RoutesEnum.CREATE_USER,
    [LocalePrefixesEnum.DE]: RoutesEnum.CREATE_USER,
    // [LocalePrefixesEnum.CH]: RoutesEnum.RoutesEnum.CREATE_USER,
  },
  [RoutesEnum.NOT_PAGE]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.NOT_PAGE,
    [LocalePrefixesEnum.AT]: RoutesEnum.NOT_PAGE,
    [LocalePrefixesEnum.DE]: RoutesEnum.NOT_PAGE,
    // [LocalePrefixesEnum.CH]: RoutesEnum.RoutesEnum.NOT_PAGE,
  },
  [RoutesEnum.OFFER]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.OFFER,
    [LocalePrefixesEnum.AT]: RoutesEnum.OFFER,
    [LocalePrefixesEnum.DE]: RoutesEnum.OFFER,
    // [LocalePrefixesEnum.CH]: RoutesEnum.OFFER,
  },
  [RoutesEnum.SAAS]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.SAAS,
    [LocalePrefixesEnum.AT]: RoutesEnum.SAAS,
    [LocalePrefixesEnum.DE]: RoutesEnum.SAAS,
    // [LocalePrefixesEnum.CH]: RoutesEnum.SAAS,
  },
  [RoutesEnum.SIGN_IN]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.SIGN_IN,
    [LocalePrefixesEnum.AT]: 'einloggen',
    [LocalePrefixesEnum.DE]: 'einloggen',
    // [LocalePrefixesEnum.CH]: RoutesEnum.SIGN_IN,
  },
  [RoutesEnum.WORKSPACE_CREATED]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.WORKSPACE_CREATED,
    [LocalePrefixesEnum.AT]: 'arbeitsbereich',
    [LocalePrefixesEnum.DE]: 'arbeitsbereich',
    // [LocalePrefixesEnum.CH]: RoutesEnum.WORKSPACE_CREATED,
  },
  [RoutesEnum.RELEASE_NOTES]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.RELEASE_NOTES,
    [LocalePrefixesEnum.AT]: 'versionshinweise',
    [LocalePrefixesEnum.DE]: 'versionshinweise',
    // [LocalePrefixesEnum.CH]: RoutesEnum.RELEASE_NOTES,
  },
  [RoutesEnum.SURVEY]: {
    [LocalePrefixesEnum.EN]: RoutesEnum.SURVEY,
    [LocalePrefixesEnum.AT]: RoutesEnum.SURVEY,
    [LocalePrefixesEnum.DE]: RoutesEnum.SURVEY,
  },
};
