<footer class="footer">
  <div class="footer-container">
    <div class="group-list">
<!--      Product-->
      <div #productGroup class="group-container toggle-container">
        <div class="group-list-title" (click)="productGroup.toggleAttribute('show')">
          <h3 class="title">{{'MAIN.PRODUCT' | translate}}</h3>
          <img height="24" width="24" ngSrc="assets/img/chevron-up.svg" alt="">
        </div>

        <div class="group-list-container">
          <div class="group-title-container grid-1">
            <h3 class="title">{{'MAIN.PRODUCT' | translate}}</h3>

            <div class="actions">
              <div class="button">
                <button type="button" class="btn btn-md btn-black" aria-label="Log in" (click)="goToSignIn()">
                  <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.7019 4.7019C6.3114 4.09241 7.13805 3.75 8 3.75H17C17.862 3.75 18.6886 4.09241 19.2981 4.7019C19.9076 5.3114 20.25 6.13805 20.25 7V17C20.25 17.862 19.9076 18.6886 19.2981 19.2981C18.6886 19.9076 17.862 20.25 17 20.25H8C7.13805 20.25 6.3114 19.9076 5.7019 19.2981C5.09241 18.6886 4.75 17.862 4.75 17V16C4.75 15.5858 4.41421 15.25 4 15.25C3.58579 15.25 3.25 15.5858 3.25 16V17C3.25 18.2598 3.75044 19.468 4.64124 20.3588C5.53204 21.2496 6.74022 21.75 8 21.75H17C18.2598 21.75 19.468 21.2496 20.3588 20.3588C21.2496 19.468 21.75 18.2598 21.75 17V7C21.75 5.74022 21.2496 4.53204 20.3588 3.64124C19.468 2.75044 18.2598 2.25 17 2.25H8C6.74022 2.25 5.53204 2.75044 4.64124 3.64124C3.75044 4.53204 3.25 5.74022 3.25 7V8C3.25 8.41421 3.58579 8.75 4 8.75C4.41421 8.75 4.75 8.41421 4.75 8V7C4.75 6.13805 5.09241 5.3114 5.7019 4.7019Z" fill="white"/>
                    <path d="M10.4697 8.46967C10.7626 8.17678 11.2374 8.17678 11.5303 8.46967L14.5303 11.4697C14.6022 11.5416 14.6565 11.6245 14.6931 11.7129C14.7298 11.8013 14.75 11.8983 14.75 12C14.75 12.1919 14.6768 12.3839 14.5303 12.5303L11.5303 15.5303C11.2374 15.8232 10.7626 15.8232 10.4697 15.5303C10.1768 15.2374 10.1768 14.7626 10.4697 14.4697L12.1893 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H12.1893L10.4697 9.53033C10.1768 9.23744 10.1768 8.76256 10.4697 8.46967Z" fill="white"/>
                  </svg>
                  {{'MAIN.SIGN_IN' | translate}}
                </button>
              </div>

              <div class="button">
                <button type="button" class="btn btn-md btn-black" aria-label="Contact support" (click)="contactSupportModal()">
<!--                  <a href="mailto:support@runple.com">-->
                    <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.10571 4.10571C6.93419 2.27723 9.41414 1.25 12 1.25C14.5859 1.25 17.0658 2.27723 18.8943 4.10571C20.7228 5.93419 21.75 8.41414 21.75 11V15C21.75 16.5188 20.5188 17.75 19 17.75C17.4812 17.75 16.25 16.5188 16.25 15V12C16.25 10.4812 17.4812 9.25 19 9.25C19.3947 9.25 19.77 9.33317 20.1094 9.48293C19.8055 7.85903 19.0184 6.35109 17.8336 5.16637C16.2865 3.61919 14.188 2.75 12 2.75C9.81196 2.75 7.71354 3.61919 6.16637 5.16637C4.98165 6.35109 4.19446 7.85903 3.89065 9.48293C4.22996 9.33317 4.60527 9.25 5 9.25C6.51878 9.25 7.75 10.4812 7.75 12V15C7.75 16.2571 6.90654 17.3171 5.75468 17.6452C5.78981 18.1889 6.0214 18.7034 6.40901 19.091C6.83097 19.5129 7.40326 19.75 8 19.75H8.87803C9.18691 18.8761 10.0203 18.25 11 18.25H13C14.2426 18.25 15.25 19.2574 15.25 20.5C15.25 21.7426 14.2426 22.75 13 22.75H11C10.0203 22.75 9.18691 22.1239 8.87803 21.25H8C7.00544 21.25 6.05161 20.8549 5.34835 20.1517C4.67988 19.4832 4.28985 18.5883 4.2529 17.6473C3.09716 17.3218 2.25 16.2598 2.25 15V11C2.25 8.41414 3.27723 5.93419 5.10571 4.10571ZM5 16.25C5.69036 16.25 6.25 15.6904 6.25 15V12C6.25 11.3096 5.69036 10.75 5 10.75C4.30964 10.75 3.75 11.3096 3.75 12V15C3.75 15.6904 4.30964 16.25 5 16.25ZM10.25 20.5C10.25 20.9142 10.5858 21.25 11 21.25H13C13.4142 21.25 13.75 20.9142 13.75 20.5C13.75 20.0858 13.4142 19.75 13 19.75H11C10.5858 19.75 10.25 20.0858 10.25 20.5ZM20.25 12C20.25 11.3096 19.6904 10.75 19 10.75C18.3096 10.75 17.75 11.3096 17.75 12V15C17.75 15.6904 18.3096 16.25 19 16.25C19.6904 16.25 20.25 15.6904 20.25 15V12Z" fill="white"/>
                    </svg>
                    {{'MAIN.CONTACT_SUPPORT' | translate}}
<!--                  </a>-->
                </button>

                <p class="additional-info">
                  {{'MAIN.WORK_TIME' | translate}}
                </p>
              </div>

              <div class="nav-link">
                <a [routerLink]="localeService.getLink(routesEnum.RELEASE_NOTES)" class="link">
                  {{'MAIN.RELEASE_NOTES' | translate}}
                </a>
              </div>
            </div>
          </div>
          <div class="group-navigation-container grid-2">
            <h4 class="title font-500">{{'MAIN.SOLUTIONS' | translate}}</h4>
            <ul class="nav-list">
              <li *ngFor="let branch of branchesList; trackBy: trackByFn" class="nav-item">
                <a [routerLink]="branch?.routerLink" class="link">
                  <img height="24" width="24" ngSrc="assets/img/app-icon/{{branch?.img}}" [alt]="branch?.title">
                  <span class="title">{{branch.title | translate}}</span>
                </a>
              </li>
            </ul>
          </div>
          <div class="group-navigation-container grid-3">
            <h4 class="title font-500">{{'MAIN.FEATURES' | translate}}</h4>
            <ul class="nav-list column-2">
              <li *ngFor="let feature of featuresList; trackBy: trackByFn" class="nav-item">
                <a [routerLink]="feature?.routerLink" class="link">
                  {{feature.title | translate}}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="group-list-actions">
          <div class="btn-container">
            <button type="button" class="btn btn-md btn-black" aria-label="Log in" (click)="goToSignIn()">
              <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.7019 4.7019C6.3114 4.09241 7.13805 3.75 8 3.75H17C17.862 3.75 18.6886 4.09241 19.2981 4.7019C19.9076 5.3114 20.25 6.13805 20.25 7V17C20.25 17.862 19.9076 18.6886 19.2981 19.2981C18.6886 19.9076 17.862 20.25 17 20.25H8C7.13805 20.25 6.3114 19.9076 5.7019 19.2981C5.09241 18.6886 4.75 17.862 4.75 17V16C4.75 15.5858 4.41421 15.25 4 15.25C3.58579 15.25 3.25 15.5858 3.25 16V17C3.25 18.2598 3.75044 19.468 4.64124 20.3588C5.53204 21.2496 6.74022 21.75 8 21.75H17C18.2598 21.75 19.468 21.2496 20.3588 20.3588C21.2496 19.468 21.75 18.2598 21.75 17V7C21.75 5.74022 21.2496 4.53204 20.3588 3.64124C19.468 2.75044 18.2598 2.25 17 2.25H8C6.74022 2.25 5.53204 2.75044 4.64124 3.64124C3.75044 4.53204 3.25 5.74022 3.25 7V8C3.25 8.41421 3.58579 8.75 4 8.75C4.41421 8.75 4.75 8.41421 4.75 8V7C4.75 6.13805 5.09241 5.3114 5.7019 4.7019Z" fill="white"/>
                <path d="M10.4697 8.46967C10.7626 8.17678 11.2374 8.17678 11.5303 8.46967L14.5303 11.4697C14.6022 11.5416 14.6565 11.6245 14.6931 11.7129C14.7298 11.8013 14.75 11.8983 14.75 12C14.75 12.1919 14.6768 12.3839 14.5303 12.5303L11.5303 15.5303C11.2374 15.8232 10.7626 15.8232 10.4697 15.5303C10.1768 15.2374 10.1768 14.7626 10.4697 14.4697L12.1893 12.75H3C2.58579 12.75 2.25 12.4142 2.25 12C2.25 11.5858 2.58579 11.25 3 11.25H12.1893L10.4697 9.53033C10.1768 9.23744 10.1768 8.76256 10.4697 8.46967Z" fill="white"/>
              </svg>
              {{'MAIN.SIGN_IN' | translate}}
            </button>
          </div>

          <div class="btn-container">
            <button type="button" class="btn btn-md btn-black" aria-label="Contact support" (click)="contactSupportModal()">
<!--              <a href="mailto:support@runple.com">-->
                <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.10571 4.10571C6.93419 2.27723 9.41414 1.25 12 1.25C14.5859 1.25 17.0658 2.27723 18.8943 4.10571C20.7228 5.93419 21.75 8.41414 21.75 11V15C21.75 16.5188 20.5188 17.75 19 17.75C17.4812 17.75 16.25 16.5188 16.25 15V12C16.25 10.4812 17.4812 9.25 19 9.25C19.3947 9.25 19.77 9.33317 20.1094 9.48293C19.8055 7.85903 19.0184 6.35109 17.8336 5.16637C16.2865 3.61919 14.188 2.75 12 2.75C9.81196 2.75 7.71354 3.61919 6.16637 5.16637C4.98165 6.35109 4.19446 7.85903 3.89065 9.48293C4.22996 9.33317 4.60527 9.25 5 9.25C6.51878 9.25 7.75 10.4812 7.75 12V15C7.75 16.2571 6.90654 17.3171 5.75468 17.6452C5.78981 18.1889 6.0214 18.7034 6.40901 19.091C6.83097 19.5129 7.40326 19.75 8 19.75H8.87803C9.18691 18.8761 10.0203 18.25 11 18.25H13C14.2426 18.25 15.25 19.2574 15.25 20.5C15.25 21.7426 14.2426 22.75 13 22.75H11C10.0203 22.75 9.18691 22.1239 8.87803 21.25H8C7.00544 21.25 6.05161 20.8549 5.34835 20.1517C4.67988 19.4832 4.28985 18.5883 4.2529 17.6473C3.09716 17.3218 2.25 16.2598 2.25 15V11C2.25 8.41414 3.27723 5.93419 5.10571 4.10571ZM5 16.25C5.69036 16.25 6.25 15.6904 6.25 15V12C6.25 11.3096 5.69036 10.75 5 10.75C4.30964 10.75 3.75 11.3096 3.75 12V15C3.75 15.6904 4.30964 16.25 5 16.25ZM10.25 20.5C10.25 20.9142 10.5858 21.25 11 21.25H13C13.4142 21.25 13.75 20.9142 13.75 20.5C13.75 20.0858 13.4142 19.75 13 19.75H11C10.5858 19.75 10.25 20.0858 10.25 20.5ZM20.25 12C20.25 11.3096 19.6904 10.75 19 10.75C18.3096 10.75 17.75 11.3096 17.75 12V15C17.75 15.6904 18.3096 16.25 19 16.25C19.6904 16.25 20.25 15.6904 20.25 15V12Z" fill="white"/>
                </svg>
                {{'MAIN.CONTACT_SUPPORT' | translate}}
<!--              </a>-->
            </button>
            <p class="additional-info">
              {{'MAIN.WORK_TIME' | translate}}
            </p>
          </div>

          <div class="btn-container">
            <a [routerLink]="localeService.getLink(routesEnum.RELEASE_NOTES)" class="link">
              {{'MAIN.RELEASE_NOTES' | translate}}
            </a>
          </div>

        </div>
      </div>

  <!--      Company-->
      <div #companyGroup class="group-container toggle-container">
        <div class="group-list-title" (click)="companyGroup.toggleAttribute('show')">
          <h3 class="title">{{'MAIN.COMPANY' | translate}}</h3>
          <img height="24" width="24" ngSrc="assets/img/chevron-up.svg" alt="">
        </div>

        <div class="group-list-container">
          <div class="group-title-container grid-1">
            <h3 class="title">{{'MAIN.COMPANY' | translate}}</h3>
            <div class="actions">
              <button type="button" class="btn btn-md btn-black" aria-label="Contact us" (click)="contactusModal()">
                <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.2997 8.29627C6.92 8.13072 6.478 8.30432 6.31245 8.68401C6.1469 9.0637 6.3205 9.50571 6.70019 9.67126L10.9048 11.5045C11.2502 11.6551 11.6229 11.7331 11.9998 11.7337C12.3766 11.7342 12.7495 11.6574 13.0954 11.5078L17.2976 9.69078C17.6778 9.52639 17.8527 9.08491 17.6883 8.70472C17.524 8.32452 17.0825 8.14958 16.7023 8.31398L12.5 10.131C12.3428 10.199 12.1733 10.2339 12.002 10.2337C11.8307 10.2334 11.6613 10.198 11.5043 10.1295L7.2997 8.29627Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4.25C4.42622 4.25 2.25 6.20936 2.25 8.73333V15.2667C2.25 17.7906 4.42622 19.75 7 19.75H17C19.5738 19.75 21.75 17.7906 21.75 15.2667V8.73333C21.75 6.20936 19.5738 4.25 17 4.25H7ZM3.75 8.73333C3.75 7.13358 5.1555 5.75 7 5.75H17C18.8445 5.75 20.25 7.13358 20.25 8.73333V15.2667C20.25 16.8664 18.8445 18.25 17 18.25H7C5.1555 18.25 3.75 16.8664 3.75 15.2667V8.73333Z" fill="white"/>
                </svg>
                {{'MAIN.CONTACT_US' | translate}}
              </button>
            </div>
          </div>
          <div class="group-navigation-container grid-2">
            <ul class="nav-list">
              <li class="nav-item">
                <a [routerLink]="localeService.getLink(routesEnum.ABOUT)" class="link">
                  {{'MAIN.ABOUT_RUNPLE' | translate}}
                </a>
              </li>

              <li class="nav-item" *ngIf="localeService.isLocaleAT">
                <a [routerLink]="localeService.getLink(routesEnum.BLOG)" class="link">
                  {{'MAIN.BLOG' | translate}}
                </a>
              </li>
            </ul>
          </div>
          <div class="group-navigation-container grid-3">
            <ul class="nav-list social">
              <li class="nav-item">
                <a href="https://www.facebook.com/runple.gmbh" target="_blank" rel="nofollow" class="link" aria-label="Facebook">
                  <svg class="social-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2350_1965)">
                      <path class="social-icon-path" fill-rule="evenodd" clip-rule="evenodd" d="M17.8467 0C19.9863 0 20.7622 0.222781 21.5444 0.641119C22.3267 1.05946 22.9405 1.67335 23.3589 2.45557C23.7772 3.23779 24 4.01368 24 6.15332V17.8467C24 19.9863 23.7772 20.7622 23.3589 21.5444C22.9405 22.3267 22.3267 22.9405 21.5444 23.3589C20.7622 23.7772 19.9863 24 17.8467 24H6.15332C4.01368 24 3.23779 23.7772 2.45557 23.3589C1.67335 22.9405 1.05946 22.3267 0.641119 21.5444C0.222781 20.7622 0 19.9863 0 17.8467L0 6.15332C0 4.01368 0.222781 3.23779 0.641119 2.45557C1.05946 1.67335 1.67335 1.05946 2.45557 0.641119C3.23779 0.222781 4.01368 0 6.15332 0L17.8467 0ZM12.9044 4.65535C10.2374 4.65535 9.63136 6.65307 9.63136 7.92884V9.71286H8.08842V12.4697H9.63136V20.3143H12.8755V12.4697H15.0168L15.3002 9.71543H12.8758V8.09291C12.8758 7.48433 13.2779 7.34144 13.5639 7.34144H15.3075V4.66541L12.9044 4.65535Z" fill="white"/>
                    </g>
                  </svg>
                </a>
              </li>

              <li class="nav-item">
                <a href="https://twitter.com/runple_gmbh" target="_blank" rel="nofollow" class="link" aria-label="Twitter">
                  <svg class="social-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.5444 0.641119C20.7622 0.222781 19.9863 0 17.8467 0H6.15332C4.01368 0 3.23779 0.222781 2.45557 0.641119C1.67335 1.05946 1.05946 1.67335 0.641119 2.45557C0.222781 3.23779 0 4.01368 0 6.15332V17.8467C0 19.9863 0.222781 20.7622 0.641119 21.5444C1.05946 22.3267 1.67335 22.9405 2.45557 23.3589C3.23779 23.7772 4.01368 24 6.15332 24H17.8467C19.9863 24 20.7622 23.7772 21.5444 23.3589C22.3267 22.9405 22.9405 22.3267 23.3589 21.5444C23.7772 20.7622 24 19.9863 24 17.8467V6.15332C24 4.01368 23.7772 3.23779 23.3589 2.45557C22.9405 1.67335 22.3267 1.05946 21.5444 0.641119ZM18.2457 5L13.1486 10.925H13.1483L18.6919 18.9928H14.6148L10.8817 13.5599L6.20792 18.9928H5L10.3455 12.7795L5 5H9.07709L12.612 10.1446L17.0378 5H18.2457ZM10.9525 12.0734L11.4941 12.8481V12.8484L15.1831 18.1249H17.0384L12.5178 11.6586L11.9762 10.8839L8.49846 5.90931H6.64316L10.9525 12.0734Z" fill="white"/>
                  </svg>
                </a>
              </li>

              <li class="nav-item">
                <a href="https://www.instagram.com/runple.gmbh" target="_blank" rel="nofollow" class="link" aria-label="Instagram">
                  <svg class="social-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2350_1982)">
                      <path class="social-icon-path" fill-rule="evenodd" clip-rule="evenodd" d="M15.5766 6.38517H8.42345C7.28171 6.38517 6.35282 7.31407 6.35282 8.4558V15.6089C6.35282 16.7506 7.28171 17.6798 8.42345 17.6798H15.5766C16.7183 17.6798 17.6475 16.7509 17.6475 15.6089V8.4558C17.6475 7.31407 16.7186 6.38517 15.5766 6.38517ZM12 15.7506C9.94995 15.7506 8.282 14.0827 8.282 12.0324C8.282 9.98231 9.94995 8.31436 12 8.31436C14.0503 8.31436 15.7183 9.98231 15.7183 12.0324C15.7183 14.0824 14.05 15.7506 12 15.7506ZM17.8467 0C19.9863 0 20.7622 0.222781 21.5444 0.641119C22.3267 1.05946 22.9405 1.67335 23.3589 2.45557C23.7772 3.23779 24 4.01368 24 6.15332V17.8467C24 19.9863 23.7772 20.7622 23.3589 21.5444C22.9405 22.3267 22.3267 22.9405 21.5444 23.3589C20.7622 23.7772 19.9863 24 17.8467 24H6.15332C4.01368 24 3.23779 23.7772 2.45557 23.3589C1.67335 22.9405 1.05946 22.3267 0.641119 21.5444C0.222781 20.7622 0 19.9863 0 17.8467L0 6.15332C0 4.01368 0.222781 3.23779 0.641119 2.45557C1.05946 1.67335 1.67335 1.05946 2.45557 0.641119C3.23779 0.222781 4.01368 0 6.15332 0L17.8467 0ZM15.5766 4.81401H8.42345C6.41538 4.81401 4.78166 6.44774 4.78166 8.4558V15.6089C4.78166 17.6172 6.41538 19.2507 8.42345 19.2507H15.5766C17.5849 19.2507 19.2183 17.6172 19.2183 15.6089V8.4558C19.2183 6.44774 17.5849 4.81401 15.5766 4.81401ZM12 9.88551C13.1837 9.88551 14.1468 10.8489 14.1468 12.0321C14.1468 13.2161 13.184 14.1795 12 14.1795C10.8166 14.1795 9.85289 13.2161 9.85289 12.0321C9.85289 10.8486 10.8166 9.88551 12 9.88551ZM15.8378 7.32369C16.3228 7.32369 16.7175 7.71835 16.7175 8.20339C16.7175 8.68843 16.3228 9.08309 15.8378 9.08309C15.3525 9.08309 14.9578 8.68843 14.9578 8.20339C14.9578 7.71835 15.3525 7.32369 15.8378 7.32369Z" fill="white"/>
                    </g>
                  </svg>
                </a>
              </li>

              <li class="nav-item">
                <a href="https://www.linkedin.com/company/runple" target="_blank" rel="nofollow" class="link" aria-label="linkedin">
                  <svg class="social-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_2350_1991)">
                      <path class="social-icon-path" fill-rule="evenodd" clip-rule="evenodd" d="M17.8467 0C19.9863 0 20.7622 0.222781 21.5444 0.641119C22.3267 1.05946 22.9405 1.67335 23.3589 2.45557C23.7772 3.23779 24 4.01368 24 6.15332V17.8467C24 19.9863 23.7772 20.7622 23.3589 21.5444C22.9405 22.3267 22.3267 22.9405 21.5444 23.3589C20.7622 23.7772 19.9863 24 17.8467 24H6.15332C4.01368 24 3.23779 23.7772 2.45557 23.3589C1.67335 22.9405 1.05946 22.3267 0.641119 21.5444C0.222781 20.7622 0 19.9863 0 17.8467L0 6.15332C0 4.01368 0.222781 3.23779 0.641119 2.45557C1.05946 1.67335 1.67335 1.05946 2.45557 0.641119C3.23779 0.222781 4.01368 0 6.15332 0L17.8467 0ZM14.5202 9.12013C11.9355 9.12013 9.83962 11.2032 9.83962 13.7711V18.4789C9.83962 18.8765 10.1628 19.1997 10.5604 19.1997H12.2395C12.6371 19.1997 12.9603 18.8765 12.9603 18.4789V13.7711C12.9603 12.8624 13.7474 12.136 14.6818 12.2296C15.493 12.3104 16.0793 13.052 16.0793 13.8671V18.4789C16.0793 18.8765 16.4025 19.1997 16.8001 19.1997H18.4792C18.8768 19.1997 19.2 18.8765 19.2 18.4789V13.7711C19.2 11.2032 17.1041 9.12013 14.5202 9.12013ZM7.67987 9.83962H5.52077C5.12319 9.83962 4.8 10.162 4.8 10.5596V18.4792C4.8 18.8768 5.12319 19.1992 5.52077 19.1992H7.67987C8.07745 19.1992 8.39984 18.8768 8.39984 18.4792V10.5596C8.39984 10.162 8.07745 9.83962 7.67987 9.83962ZM6.60024 4.8C5.60668 4.8 4.80032 5.60636 4.80032 6.59992C4.80032 7.59348 5.60668 8.39984 6.60024 8.39984C7.5938 8.39984 8.40016 7.59348 8.40016 6.59992C8.40016 5.60636 7.5938 4.8 6.60024 4.8Z" fill="white"/>
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="group-list-actions">
          <button type="button" class="btn btn-md btn-black" aria-label="Contact us" (click)="contactusModal()">
            <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.2997 8.29627C6.92 8.13072 6.478 8.30432 6.31245 8.68401C6.1469 9.0637 6.3205 9.50571 6.70019 9.67126L10.9048 11.5045C11.2502 11.6551 11.6229 11.7331 11.9998 11.7337C12.3766 11.7342 12.7495 11.6574 13.0954 11.5078L17.2976 9.69078C17.6778 9.52639 17.8527 9.08491 17.6883 8.70472C17.524 8.32452 17.0825 8.14958 16.7023 8.31398L12.5 10.131C12.3428 10.199 12.1733 10.2339 12.002 10.2337C11.8307 10.2334 11.6613 10.198 11.5043 10.1295L7.2997 8.29627Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7 4.25C4.42622 4.25 2.25 6.20936 2.25 8.73333V15.2667C2.25 17.7906 4.42622 19.75 7 19.75H17C19.5738 19.75 21.75 17.7906 21.75 15.2667V8.73333C21.75 6.20936 19.5738 4.25 17 4.25H7ZM3.75 8.73333C3.75 7.13358 5.1555 5.75 7 5.75H17C18.8445 5.75 20.25 7.13358 20.25 8.73333V15.2667C20.25 16.8664 18.8445 18.25 17 18.25H7C5.1555 18.25 3.75 16.8664 3.75 15.2667V8.73333Z" fill="white"/>
            </svg>

            {{'MAIN.CONTACT_US' | translate}}
          </button>
        </div>
      </div>

<!--      Usage scenarios-->
      <div *ngIf="!!localeService.currentLocalePrefix" #scenariosGroup class="group-container toggle-container">
        <div class="group-list-title" (click)="scenariosGroup.toggleAttribute('show')">
          <h3 class="title">{{'MAIN.USE_CASES' | translate}}</h3>
          <img height="24" width="24" ngSrc="assets/img/chevron-up.svg" alt="">
        </div>

        <div class="group-list-container">
          <div class="group-title-container grid-1">
            <h3 class="title">{{'MAIN.USE_CASES' | translate}}</h3>
          </div>
          <div class="group-navigation-container grid-4">
            <ul class="nav-list column-3">
              <li *ngFor="let item of usageScenariosList; trackBy: trackByFn" class="nav-item">
                <a [routerLink]="item?.routerLink" class="link">
                  {{item.title | translate}}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

<!--      Select country-->
      <div class="group-container">
        <div class="group-list-container group-list-container-1-2-1">
          <div class="group-select-country-container grid-1">
            <ng-select
              appendTo="html"
              #selectCountry
              [dropdownPosition]="'auto'"
              [items]="selectItems"
              [searchable]="false"
              [(ngModel)]="selectedRegion"
              (change)="changeRegion($event)"
            >
              <ng-template ng-label-tmp let-item="item">
                <div class="d-flex align-items-center selected-item">
                  <img height="24" width="24" [ngSrc]="item.img" [alt]="''">
                  <span>{{item.label | translate}}</span>
                </div>
              </ng-template>

              <ng-template ng-option-tmp let-item="item" let-index="index">
                <img height="24" width="24" [ngSrc]="item.img" [alt]="''">
                <span>{{item.label | translate}}</span>

                <img class="selected" height="24" width="24" ngSrc="assets/img/check.svg" [alt]="''">
              </ng-template>
            </ng-select>
          </div>
          <div class="group-select-country-container grid-2">
            <ul class="nav-list d-flex">
              <li class="nav-item mr-24">
                <a href="https://apps.apple.com/app/runple/id6742907148" target="_blank">
                  <img class="store-img app-store" height="48" width="144" ngSrc="assets/img/footer/app-store.svg" [alt]="''">
                </a>
              </li>
              <li class="nav-item">
                <a href="https://play.google.com/store/apps/details?id=com.runple.mobile" target="_blank">
                  <img class="store-img play-store" height="48" width="160" ngSrc="assets/img/footer/play-store.svg" [alt]="''">
                </a>
              </li>
            </ul>
          </div>
          <div class="group-navigation-container grid-3">
            <ul class="nav-list">
              <li *ngIf="!!localeService.currentLocalePrefix" class="nav-item ml-24">
                <a [routerLink]="localeService.getLink(routesEnum.LEXICON)" class="link">
                  {{'MAIN.LEXICON' | translate}}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="group-list-actions">
<!--          trustpilot-->
        </div>
      </div>

<!--      Runple logo-->
      <div class="group-container">
        <div class="group-list-container">
          <div class="group-logo-container grid-1">
            <img height="48" width="129" ngSrc="assets/img/runple_logo.svg" alt="Runple">
          </div>
          <div class="group-navigation-container grid-5">
            <ul class="nav-list d-flex">
              <li class="nav-item">
                <a [routerLink]="localeService.getLink(routesEnum.LEGAL)" class="link">{{'MAIN.LEGAL_NOTICE' | translate}}</a>
              </li>

              <li class="nav-item">
                <a [routerLink]="localeService.getLink(routesEnum.TERMS_OF_SERVICE)" class="link">{{'MAIN.TERMS_OF_SERVICE' | translate}}</a>
              </li>

              <li class="nav-item">
                <a [routerLink]="localeService.getLink(routesEnum.PRIVACY_POLICY)" class="link">{{'MAIN.PRIVACY_POLICY' | translate}}</a>
              </li>

              <li class="nav-item">
                <a [routerLink]="localeService.getLink(routesEnum.COOKIE_POLICY)" class="link">{{'MAIN.COOKIES_POLICY' | translate}}</a>
              </li>

            </ul>
          </div>
          <div class="group-additional-info-container text-right grid-6">
            {{'MAIN.RUNPLE_GMBH' | translate: {currentYear: currentYear} }}
          </div>
        </div>
      </div>

<!--      Bottom info-->
      <div class="group-container">
        <div class="group-list-container text-content saas-info">
          <p class="group-bottom-info grid-7">
            {{'MAIN.SAAS_INFO' | translate}}
          </p>

          <ul class="additional-info-list">
            <li class="additional-info">
              <img src="assets/img/footer/certificate-medal.svg" height="32" width="32" alt="">
              {{'SAAS.INFO_1' | translate}}
            </li>
            <li class="additional-info">
              <img src="assets/img/footer/Group.svg" height="32" width="32" alt="">
              {{'SAAS.INFO_2' | translate}}
            </li>
            <li class="additional-info">
              <img src="assets/img/footer/shield.svg" height="32" width="32" alt="">
              {{'SAAS.INFO_3' | translate}}
            </li>
          </ul>

          <a [routerLink]="localeService.getLink(routesEnum.SAAS)" class="link">
            {{'MAIN.LEARN_MORE_FOOTER' | translate}}

            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5303 17.5303L18.5303 12.5303C18.8232 12.2374 18.8232 11.7626 18.5303 11.4697L13.5303 6.46967C13.2374 6.17678 12.7626 6.17678 12.4697 6.46967C12.1768 6.76256 12.1768 7.23744 12.4697 7.53033L16.1893 11.25L5 11.25C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75L16.1893 12.75L12.4697 16.4697C12.1768 16.7626 12.1768 17.2374 12.4697 17.5303C12.7626 17.8232 13.2374 17.8232 13.5303 17.5303Z" fill="#9CA4BE"/>
            </svg>
          </a>
        </div>

        <div class="group-list-container text-content">
          <p *ngIf="(showAdditionalInfo$ | async)" class="group-bottom-info grid-7">
            <sup>1 </sup>
            {{'MAIN.THE_METRICS_SHOWN_ARE_FOR' | translate}}
          </p>

          <p *ngIf="(showAdditionalInfoCta$ | async)" class="group-bottom-info grid-7">
            <sup>{{(showAdditionalInfo$ | async) ? '2 ' : '1 '}}</sup>
            <span [innerHTML]="'MAIN.WE_ARE_USING_GOOGLE' | translate"></span>
          </p>

          <p class="group-bottom-info grid-7">
            <sup>{{(showAdditionalInfo$ | async) && (showAdditionalInfoCta$ | async) ? '3' : (showAdditionalInfo$ | async) || (showAdditionalInfoCta$ | async) ? '2 ' : '1 '}}</sup>
            {{'MAIN.ALL_PRODUCT_NAMES' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>
